import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import {useTranslation} from "react-i18next";
import i18n from "../../helpers/i18n";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
              {" "}
              {t('men')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('spikes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=running&page=1"}>
                      {t('road')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=trail&page=1"}>
                      {t('trail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Cuissards&page=1"}>
                      {t('halftigh')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Men&page=1"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Men&category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
                      {t('brands')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=adidas&page=1"}>
                      adidas
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Asics&page=1"}>
                      Asics
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Hoka%20One%20One&page=1"}>
                      Hoka One One
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=New%20Balance&page=1"}>
                      New Balance
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Nike&page=1"}>
                      Nike
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=On%20Running&page=1"}>
                      On Running
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Puma&page=1"}>
                      Puma
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&brands=Saucony&page=1"}>
                      Saucony
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Men&page=1"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/test-h-menu.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
              {" "}
              {t('women')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?page=1"}>
                      {t('spikes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=running&page=1"}>
                      {t('road')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=trail&page=1"}>
                      {t('trail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Brassières&page=1"}>
                      {t('bra')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?genders=Women"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?genders=Women&category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
                      {t('brands')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=adidas&page=1"}>
                      adidas
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Asics&page=1"}>
                      Asics
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Hoka%20One%20One&page=1"}>
                      Hoka One One
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=New%20Balance&page=1"}>
                      New Balance
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Nike&page=1"}>
                      Nike
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=On%20Running&page=1"}>
                      On Running
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Puma&page=1"}>
                      Puma
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&brands=Saucony&page=1"}>
                      Saucony
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/products?genders=Women&page=1"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/femme-menu-1.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/athletisme"}>
              {" "}
              {t('trackandfield')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=athletisme&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Entrainements&shopType=athletisme&page=1"}>
                      {t('training')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Compétitions&shopType=athletisme&page=1"}>
                      {t('competition')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=athletisme&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/spikes?shopType=athletisme&page=1"}>
                      {t('spikes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Cross&page=1"}>
                      {t('cross')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Demi-fond&page=1"}>
                      {t('middledistance')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Sprint&page=1"}>
                      {t('sprint')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes?category=Autres&page=1"}>
                      {t('others')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/spikes"}>
                      {t('allspikes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Brassières&page=1"}>
                      {t('bra')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=athletisme"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?shopType=athletisme&category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/athletisme"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/spikes-menu-1.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/running"}>
              {" "}
              {t('running')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=running&page=1"}>
                      {t('gender')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=running&page=1"}>
                      {t('men')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=running&page=1"}>
                      {t('women')}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=running&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Neutre&shopType=running&page=1"}>
                      {t('neutral')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Stabilité&shopType=running&page=1"}>
                      {t('stability')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Pronateur&shopType=running&page=1"}>
                      {t('pronate')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Entrainements&shopType=running&page=1"}>
                      {t('training')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Compétitions&shopType=running&page=1"}>
                      {t('competition')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=running&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Brassières&page=1"}>
                      {t('bra')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running&category=Equipe%20de%20France&page=1"}>
                      {t('frenchteam')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=running"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/running"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/running-nemu1.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/trail"}>
              {" "}
              {t('trail')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="mega-menu">
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=trail&page=1"}>
                      {t('gender')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Men&shopType=trail&page=1"}>
                      {t('men')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?genders=Women&shopType=trail&page=1"}>
                      {t('women')}
                    </a>
                  </li>
                </ul>
              </li>
              <li className="mega-4">
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=trail&page=1"}>
                      {t('shoes')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Trail%20court&shopType=trail&page=1"}>
                      {t('shorttrail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Trail%20long&shopType=trail&page=1"}>
                      {t('longtrail')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?category=Claquettes&page=1"}>
                      {t('thongs')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/shoes?shopType=trail&page=1"}>
                      {t('allshoes')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&page=1"}>
                      {t('apparel')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Brassières&page=1"}>
                      {t('bra')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Débardeurs&page=1"}>
                      {t('singlet')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Tee-shirts&page=1"}>
                      {t('teeshirt')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Vestes&page=1"}>
                      {t('hoodies')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Shorts&page=1"}>
                      {t('short')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail&category=Collants&page=1"}>
                      {t('longtight')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/apparel?shopType=trail"}>
                      {t('allproducts')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('accessories')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('food')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('electronic')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <ul>
                  <li className="mega-menu-img">
                    <a href={process.env.PUBLIC_URL + "/trail"}>
                      <img
                          src={
                              process.env.PUBLIC_URL +
                              "/assets/img/trail-menu-1.jpg"
                          }
                          alt=""
                          style={{maxWidth: '200px'}}
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products/accessories"}>
              {t('accessories')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="submenu">
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Bonnets&page=1"}>
                      {t('hat')}
                    </a>
                  </li>

                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Chaussettes&page=1"}>
                      {t('socks')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Gants&page=1"}>
                      {t('gloves')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Lunettes%20de%20vitesse&page=1"}>
                      {t('speedglasses')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?category=Sac%20à%20dos&page=1"}>
                      {t('bag')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/accessories?page=1"}>
                      {t('allaccesories')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products/electronic"}>
              {t('electronic')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="submenu">
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Capteur%20de%20puissance&page=1"}>
                      {t('powermeter')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Ceinture%20cardiaque&page=1"}>
                      {t('hrsensor')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Electrostimulation&page=1"}>
                      {t('electrostimulation')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Lampes%20frontales&page=1"}>
                      {t('headlamps')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Montres%20GPS&page=1"}>
                      {t('gps')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Pistolets%20de%20massage&page=1"}>
                      {t('massagegun')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?category=Pressothérapie&page=1"}>
                      {t('pressotherapy')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/electronic?page=1"}>
                      {t('allelectronic')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products/nutrition"}>
              {t('food')}
              {sidebarMenu ? (
                  <span>
                  <i className="fa fa-angle-right" style={{marginLeft: '5px !important'}}></i>
                </span>
              ) : (
                  <i className="fa fa-angle-down" style={{marginLeft: '5px !important'}} />
              )}
            </a>
            <ul className="submenu">
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Barres%20énergétiques&page=1"}>
                      {t('energybar')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Boissons%20énergétiques&page=1"}>
                      {t('energydrinks')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Gels%20énergétiques&page=1"}>
                      {t('energygels')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?category=Packs&page=1"}>
                      {t('packs')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <a href={process.env.PUBLIC_URL + "/products/nutrition?page=1"}>
                      {t('allfood')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href={process.env.PUBLIC_URL + "/products/vouchers"}>
              {t('vouchers')}
            </a>
          </li>
          {
            i18n.language == "fr" ?
              <li>
                <a href={process.env.PUBLIC_URL + "/blog"}>
                  {t('blog')}
                </a>
              </li>: null
          }

          {
            i18n.language == "fr" ?
                <li>
                  <a href={process.env.PUBLIC_URL + "/podcasts"}>
                    {t('podcasts')}
                  </a>
                </li>
                : null
          }

        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

export default multilanguage(NavMenu);
