import PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import * as ReactGA from "react-ga";
import SectionTitle from "../../components/section-title/SectionTitle";
import {InputBase} from "@mui/material";
import {useTranslation} from "react-i18next";

const Allures = ({ location }) => {
  const { pathname } = location;
  const { t } = useTranslation();
    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [])

  const [error, setError] = useState(null)
  const [distance, setDistance] = useState(null)
  const [hour, setHour] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [calculated, setCalculated] = useState(false)
  const [speed, setSpeed] = useState(null)
  const [pace, setPace] = useState(null)
  const [passage, setPassage] = useState(null)

  const calculate = (distance, hour, minutes, seconds) => {
      setCalculated(true)
      if(!distance){
          setError("Distance manquante")
      } else {
          setError(null)
          let hourInSecond = parseInt(hour, 10) * 60 * 60
          let minutesInSecond = parseInt(minutes, 10) * 60
          let totalSeconds = hourInSecond + minutesInSecond + parseInt(seconds, 10)
          if (totalSeconds > 0){
              let paceInKmH = parseInt(distance, 10) / totalSeconds * 3.6
              setSpeed(parseFloat(paceInKmH).toFixed(2))
              let paceInKMMin = paceInKmH / 60
              let revert = 1 / paceInKMMin
              let rounded = parseFloat(revert).toFixed(2);
              let roundedIn = Math.floor(rounded);
              let decimal = rounded - roundedIn;
              let pace = (Math.round(parseFloat(decimal).toFixed(2) * 60) % 60)
              setPace(roundedIn + "."+ n(pace))

              let distanceModulo = Math.floor(distance / 1000)
              if(distanceModulo > 0){
                  let passageArray = []
                  for(let i = 1; i < distanceModulo + 1; i++){
                      let passage = (rounded * i)
                      let roundedIn = Math.floor(passage);
                      if(passage > 60){
                          let decimal = passage - roundedIn;
                          let pace = (Math.round(parseFloat(decimal).toFixed(2) * 60) % 60)
                          passageArray[i] = convertMinsToTime(roundedIn) + n(pace) + "s"
                      } else {
                          let decimal = passage - roundedIn;
                          let pace = (Math.round(parseFloat(decimal).toFixed(2) * 60) % 60)
                          passageArray[i] = roundedIn + "mn"+ n(pace) + "s"
                      }

                  }
                  setPassage(passageArray)
              }
          }
            else {
              setError("Données erronées")
              setSpeed(null)
          }
      }
  }

    function n(n){
        return n > 9 ? "" + n: "0" + n;
    }

    const convertMinsToTime = (mins) => {
        let hours = Math.floor(mins / 60);
        let minutes = mins % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}h:${minutes}mn`;
    }

  return (
    <Fragment>
        <div className="container">
            <div className="row" style={{textAlign: "center", fontSize: "17px"}}>
                <div className="col-12">
                    <h4><b>{t('pacecalculatorpage')}</b></h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                   <div><b>{t('principalfunctions')}</b></div>
                    <div>{t('pace1')}</div>
                    <div>{t('pace2')}</div>
                    <div>{t('pace3')}</div>
                </div>
            </div>

            <br/>
            <div className="row">
                <div className="col-4" style={{paddingTop: "10px"}}>
                    {t('distance')}
                </div>
                <div className="col-4" style={{paddingLeft: 0}}>
                    <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                        placeholder="10000" type="text" onChange={(e) => setDistance(e.target.value)} style={{width: "80%"}}/> m
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-4">
                    {t('time')}
                </div>
                <div className="col-2" style={{paddingLeft: 0, paddingRight: 0}}>
                    <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                           maxLength={2}
                           defaultValue={hour}  type="text" onChange={(e) => setHour(e.target.value)} style={{width: "50%", paddingLeft: "5px"}}/> h
                </div>
                <div className="col-2" style={{paddingLeft: 0, paddingRight: 0}}>
                    <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                           maxLength={2}
                           defaultValue={minutes} type="text" onChange={(e) => setMinutes(e.target.value)} style={{width: "50%", paddingLeft: "5px"}}/> mn
                </div>
                <div className="col-2" style={{paddingLeft: 0, paddingRight: 0}}>
                    <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                           maxLength={2}
                           defaultValue={seconds}  type="text" onChange={(e) => setSeconds(e.target.value)} style={{width: "50%", paddingLeft: "5px"}}/> s
                </div>
            </div>
        </div>
          <br/>
          <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                  <input type="button" defaultValue={t('calculate')} onClick={() => calculate(distance, hour, minutes, seconds)}/>
              </div>
          </div>
          <br/>
          {
              calculated && !error?
                  <div className="row">
                      <div className="col-12" style={{textAlign: "center"}}>
                          <p>{distance}m en {hour}h {minutes}mn {seconds}s</p>
                          <div>
                              {t('speed')} <b>{speed}km/h</b>
                          </div>
                          <div>
                              {t('pace')} <b>{pace}mn/km</b>
                          </div>
                      </div>
                      <br/>
                      <div className="col-12" style={{textAlign: "center"}}>
                          {passage && passage.length > 0 ?
                              passage.map((time, index) => {
                                  return (
                                      <p>Km {index} : {time}</p>
                                  )
                              })
                              : null }
                      </div>
                  </div>
              : error ?
                      <div className="row">
                          <div className="col-12" style={{textAlign: "center", color: "red"}}>
                          {error}
                          </div>
                      </div>
                  : null
          }
          <br/>
    </Fragment>
  );
};

Allures.propTypes = {
  location: PropTypes.object
};

export default Allures;
