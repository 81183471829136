// get products
import {newCheckExistingSize} from "../pages/shop-product/tools";

export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter(
        product => product.category.filter(single => single === category)[0]
      )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.filter(single => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      single => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    single =>
      single.id === product.id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        single =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      )[0].quantity;
    } else {
      return cartItems.filter(single => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};

export const getShowProducts = (products) => {
  let productsFiltered = products
  if (products && products.length > 0) {
    productsFiltered = productsFiltered.filter(
        product => product.showProduct === true
    );
  }

  return productsFiltered
}

//get products based on category
export const getSortedProducts = (products, sortType, sortValue, categorySortType, categorySortValue, brandSortType, brandSortValue, genderSortType, genderSortValue, colorSortType, colorSortValue, sizeSortType, sizeSortValue, priceSortType, priceSortValue, retailerSortType, retailerSortValue, weighSortType, weighSortValue, dropSortType, dropSortValue, filtersSortType, filtersSortValue) => {
  let productsFiltered = products

  if (products && products.length > 0) {

    if (products && retailerSortType && retailerSortValue) {
      if (retailerSortType === "retailer") {
        if(productsFiltered.length > 0){
          if(productsFiltered.length > 0){
            let newProductsFiltered = []
            productsFiltered.forEach(product => {
              let newProduct = product
              if(product.declinations && product.declinations.length > 0) {
                let declinations = product.declinations.sort((a, b) => (a.bestRetailer.price !=0 ? a.bestRetailer.price : a.price) - (b.bestRetailer.price !=0 ? b.bestRetailer.price : b.price)).reverse();
                declinations.forEach(declination => {
                  if (declination.analysedRetailers) {
                    declination.analysedRetailers.forEach(retailer => {
                      if (retailer.retailerName == retailerSortValue) {
                        newProduct.bestDeclinations = declination
                      }
                    })
                  }
                })
              }
              newProductsFiltered.push(newProduct)
            })
            productsFiltered.bestDeclinations = newProductsFiltered
          }
        }
      }

    }

    if (products && colorSortType && colorSortValue) {
      if (colorSortType === "color") {
        let colors = colorSortValue.split('_');

        if(productsFiltered.length > 0){
          let newProductsFiltered = []
          productsFiltered.forEach(product => {
            let newProduct = product
            if(product.declinations && product.declinations.length > 0) {
              let declinations = product.declinations.sort((a, b) => (a.bestRetailer.price !=0 ? a.bestRetailer.price : a.price) - (b.bestRetailer.price !=0 ? b.bestRetailer.price : b.price)).reverse();
              declinations.forEach(declination => {
                if (colors.includes(declination.color)) {
                  newProduct.bestDeclinations = declination
                }
              })
            }
            newProductsFiltered.push(newProduct)
          })
          productsFiltered.bestDeclinations = newProductsFiltered
        }
      }
    }

    if (products && sizeSortType && sizeSortValue) {
      if (sizeSortType === "size") {
        if(productsFiltered.length > 0){
          let newProductsFiltered = []
          productsFiltered.forEach(product => {
            let newProduct = product
            if(product.declinations && product.declinations.length > 0) {
              let declinations = product.declinations.sort((a, b) => (a.bestRetailer.price !=0 ? a.bestRetailer.price : a.price) - (b.bestRetailer.price !=0 ? b.bestRetailer.price : b.price)).reverse();
              declinations.forEach(declination => {
                if (declination.analysedRetailers) {
                  declination.analysedRetailers.forEach(retailer => {
                    if (retailer.sizes && retailer.sizes.indexOf(sizeSortValue) > -1) {
                      newProduct.bestDeclinations = declination
                    }
                  })
                }
              })
            }
            newProductsFiltered.push(newProduct)
          })
          productsFiltered.bestDeclinations = newProductsFiltered
        }
      }
    }

    if(products){
      productsFiltered =  productsFiltered.sort((a, b) => {
        return a.order - b.order;
      });
    }

    if (products && filtersSortType && filtersSortValue) {
      if (filtersSortType === "filters") {
        let sortProducts = [...productsFiltered];
        if (filtersSortValue === "Meilleures ventes" || filtersSortValue === "") {
          productsFiltered =  sortProducts.sort((a, b) => {
            return a.order - b.order;
          });
        }
        if (filtersSortValue === "Prix décroissant") {
          productsFiltered =  sortProducts.sort((a, b) => {
            if (b.bestDeclinations.bestRetailer.price > 0 && a.bestDeclinations.bestRetailer.price > 0){
              return b.bestDeclinations.bestRetailer.price - a.bestDeclinations.bestRetailer.price;
            }
          });
        }
        if (filtersSortValue === "Prix croissant") {
          productsFiltered = sortProducts.sort((a, b) => {
            if (b.bestDeclinations.bestRetailer.price > 0 && a.bestDeclinations.bestRetailer.price > 0){
              return a.bestDeclinations.bestRetailer.price - b.bestDeclinations.bestRetailer.price;
            }
          });
        }
      }
    }
  }
  return productsFiltered;
};

// get individual element
export const getIndividualItemArray = array => {
  let individualItemArray = array.filter(function(v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual categories
export const getIndividualCategories = products => {
  let productCategories = [];
  products &&
    products.map(product => {
      return (
        product.categories &&
        product.categories.map(single => {
          return productCategories.push(single);
        })
      );
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
};

// get individual tags
export const getIndividualTags = products => {
  let productTags = [];
  products &&
    products.map(product => {
      return productTags.push(product.brand);
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual tags
export const getIndividualGenders = products => {
  let productGenders = [];
  products &&
  products.map(product => {
    return productGenders.push(product.gender);
  });
  const individualProductGenders = getIndividualItemArray(productGenders);
  return individualProductGenders;
};

// get individual colors
export const getIndividualColors = products => {
  let productColors = [];
  products &&
    products.map(product => {
      return (
        product.colors &&
        product.colors.map(single => {
          return productColors.push(single);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual colors
export const getIndividualRetailers = products => {
  let productRetailers = [];
  products &&
  products.map(product => {
    return (
        product.retailers &&
        product.retailers.map(single => {
          return productRetailers.push(single);
        })
    );
  });
  const individualProductRetailers = getIndividualItemArray(productRetailers);
  return individualProductRetailers;
};

// get individual sizes
export const getProductsIndividualSizes = products => {
  let productSizes = [];
  products &&
  products.map(product => {
    return (
        product.sizes &&
        product.sizes.map(size => {
          if (size !== "Votretaille" && size !== "questions les plus utiles" && size !== "questions les plus récentes" && size !== "questions les plus commentées") {
            return productSizes.push(size);
          } else {
            return productSizes
          }
        })
    );
  });

  let uniqueSize = [...new Set(productSizes.sort())]
  const individualProductSizes = getIndividualItemArray(uniqueSize);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = product => {
  let productSizes = [];
  product.variation &&
    product.variation.map(singleVariation => {
      return (
        singleVariation.size &&
        singleVariation.size.map(singleSize => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const deleteActiveGenderSort = e => {
  e.currentTarget.classList.remove("active")
};

export const removeActiveGenderSort = () => {
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-gender button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
};


export const removeActiveSizeSort = () => {
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-size button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
};

export const removeActiveBrandsSort = () => {
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-brand button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
};

export const removeActiveColorsSort = () => {
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-color button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
};

export const removeActiveRetailersSort = () => {
  const filterButtons = document.querySelectorAll(
      ".sidebar-widget-tag-retailer button"
  );

  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
};

export const setActiveGenderSort = e => {
  if (e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-tag-gender button"
    );

    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const setActiveCategoriesSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left-category button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const setActiveColorsSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left-color button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const setActiveBrandsSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-tag-brand button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const setActiveSizesSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-tag-size button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};

export const setActiveLayout = e => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach(item => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};

export function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      //eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      //eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}


export function getRGBColorFromName(colorName, colors){
  if (colors && colors.length > 0) {
    return colors.filter(color => { return color.name === colorName})[0].hexaColor
  }
}

export function getHexaColorFromName(colorName, colors){
  if (colors && colors.length > 0) {
    let hexaColor = colors.filter(color => { return color.name === colorName})[0].hexaColor
    return rgbToHex(hexaColor.r, hexaColor.g, hexaColor.b)
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function getHighPrice(products) {
  let sortedProducts = products.sort((a, b) => {
    return b.calculatedPrice - a.calculatedPrice;
  });

  if (sortedProducts.length > 0) {
    return sortedProducts[0].calculatedPrice
  } else {
    return 1
  }
}

export function getLowPrice(products) {
  let sortedProducts = products.sort((a, b) => {
    return a.calculatedPrice - b.calculatedPrice;
  });

  if (sortedProducts.length > 0) {
    return sortedProducts[0].calculatedPrice
  } else {
    return 1
  }
}

// get individual sizes
export const newGetProductsIndividualSizes = (pathname, sizes) => {
  let productSizes = [];

  sizes.map(size => {
    if (size !== "questions les plus utiles"
        && size !== "questions les plus récentes"
        && size !== "questions les plus commentées"
        && size !== "" && newCheckExistingSize(pathname, size)) {
      return productSizes.push(size);
    } else {
      return productSizes
    }
  })

  let uniqueSize = [...new Set(productSizes.sort())]
  const individualProductSizes = getIndividualItemArray(uniqueSize);
  return individualProductSizes;

};

export const setActiveFiltersSort = e => {
  if (e.currentTarget.classList.contains('active')) {
    e.currentTarget.classList.remove("active")
  } else {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left-filter button"
    );
    filterButtons.forEach(item => {
      item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
};