import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import {slugify} from "../../helpers/product"
import {useTranslation} from "react-i18next";

const ProductGridSingleTwo = ({
  product,
  currency,
  sliderClassName,
  spaceBottomClass,
  colorClass,
  titlePriceClass
}) => {
  const { t } = useTranslation();

  const finalProductPrice = product.bestDeclinations.price;
  const discountedPrice = product.bestDeclinations.bestRetailer.price;
  const finalDiscountedPrice = +(
      discountedPrice * currency.currencyRate
  ).toFixed(2);

  const discount = product.bestDeclinations.bestRetailer.amount

  return (
    <Fragment>
      <div
        className={`col-xl-3 col-md-6 col-lg-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap-2 ${
            spaceBottomClass ? spaceBottomClass : ""
          } ${colorClass ? colorClass : ""} `}
        >
          <div className="product-img" style={{border: 'solid 1px lightgray', borderRadius: "15px"}}>
            <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
              <img
                  className="default-img"
                  src={'https://img.therunningcollective.fr/' + (product.bestDeclinations.firstImg != "" ? product.bestDeclinations.firstImg : product.declinations[0].firstImg)}
                  alt={product.name + " au meilleur prix !"}
                  style={{borderRadius: "15px"}}
              />
              {product.bestDeclinations.imgs > 0 ? (
                <img
                  className="hover-img"
                  src={'https://img.therunningcollective.fr/' +  product.bestDeclinations.imgs[0]}
                  alt={product.name + " au meilleur prix !"}
                  style={{borderRadius: "15px"}}
                />
              ) : (
                ""
              )}
            </Link>
            {discount > 0  && discount !== 100 ? (
              <div className="product-img-badges">
                {discount > 0 && discount !== 100 ? (
                  <span className="purple" style={{backgroundColor : 'black', color : 'white'}}>-{discount}%</span>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {product.availableSoon  && discount == 0 ? (
                <div className="product-img-badges">
                  <span className="purple" style={{backgroundColor : 'red', color : 'white'}}>{t("availablesoon")}</span>
                </div>
            ) : (
                ""
            )}
          </div>
          <div className="product-content-2">
            <div
              className={`title-price-wrap-2 ${
                titlePriceClass ? titlePriceClass : ""
              }`}
            >
              <h3>
                <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                  {product.name}
                </Link>
              </h3>
              <div>
                <a href={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? (product.bestDeclinations.reference ? product.shortURL + "?reference="+ product.bestDeclinations.reference : product.shortURL) : slugify(product.name))}>
                  <span style={{color: "grey", fontWeight: 500, fontSize: "13px"}}>{t(product.gender.toLowerCase())}</span>
                </a>
              </div>
              <div className="price-2">
                {discount > 0 && discount !== 100 ? (
                   <Fragment>
                     <span style={{fontWeight: '600'}}>
                       {finalDiscountedPrice + currency.currencySymbol}
                     </span>
                     <span className="old" style={{color: 'grey', fontSize: '14px', fontWeight: '600'}}>
                       {finalProductPrice + currency.currencySymbol}
                     </span>
                   </Fragment>
                ) : (
                    <span style={{fontWeight: '600'}}>{product.declinations[0].price + currency.currencySymbol} </span>
                )}
              </div>
              <Link to={process.env.PUBLIC_URL + "/product/" + (product.shortURL !== "" ? product.shortURL : slugify(product.name))}>
                <button style={{border: 'none', backgroundColor: 'black', color: 'white', borderRadius: "10px"}}>{t("compare")}</button>
              </Link>
            </div>
            {((product.posts && product.posts.length > 0) || product.trainingApproved || product.raceApproved) ?
                <div className="pro-wishlist-2">
                  <button
                  >
                    {product.posts && product.posts.length > 0 ?
                        <Link to={process.env.PUBLIC_URL + '/post/' + product.posts[0]} style={{margin: '0'}}>
                          <i title={"Ce produit a été testé par nos équipes : clique ici pour lire le compte rendu !"}
                             style={{color: 'blue'}}
                             className="fas fa-feather-alt"
                          ></i>&nbsp;
                        </Link>
                        :
                        null}
                    {product.trainingApproved ?
                        <span>
                          <i title={"Ce produit dispose du label « training » certifié par The Running Collective. " +
                          "Pour détenir ce label, un produit doit remplir au moins un des critères suivants : " +
                          "- durée de vie supérieure à 600km - historicité du modèle (nombre de versions > 5) " +
                          "- Confort de la paire testé par nos équipes"} style={{color: 'green'}} className="fas fa-running"></i>
                        </span>
                        : null}
                    {product.raceApproved ?
                        <span>
                          <i title={"Ce produit dispose du label « ultra performance » certifié par The Running Collective. " +
                          "Cela signifie qu’il a été porté lors d’un record du monde ou d’un titre mondial/olympique. " +
                          "Seuls quelques produits disposent de ce label !"} style={{color: 'gold'}} className="fas fa-trophy"></i>&nbsp;
                        </span>
                        : null}
                  </button>
                </div>
                :
                null
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ProductGridSingleTwo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
  titlePriceClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingleTwo;
