import PropTypes from "prop-types";
import React, {useEffect, Suspense, lazy, useState} from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Logout from "./components/Logout";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";
import MobileBanner from "react-mobile-banner";
import {MobileView, isMobileOnly} from "react-device-detect";
import {getWithExpiry, setWithExpiry} from "./pages/home/HomeFashionTwo";
import TagManager from 'react-gtm-module'
import {useTranslation} from "react-i18next";
import {LanguagePopup} from "./components/LanguagePop";
import {sleep} from "./helpers/tools";
import Allures from "./pages/other/Allures";

// home pages
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
 
const getToken = () => {
  const tokenString = localStorage.getItem('trc-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
};

export const getClientTokenData = () => {
  const tokenString = localStorage.getItem('trc-client-token');
  const userToken = JSON.parse(tokenString);
  return userToken?.user
};

//creating function to load ip address from the API
export const GetUserData = async (noSending, origin) => {
  var url = "https://pro.ip-api.com/json/?key=Y1hZznfd2inZrpm&fields=status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,asname,reverse,mobile,proxy,hosting,query"
  const res = await axios.get(url)
  if (res.data){
    res.data.location = window.location.href
    var query = "https://api.therunningcollective.fr/api/public/data"
    if (!noSending) {
      var clientData = getClientTokenData()
      if (clientData) {
        res.data.email = clientData.email
        res.data.login = clientData.login
        res.data.firstName = clientData.firstName
        res.data.lastName = clientData.name
        res.data.clientID = clientData.id
      }
      axios.post(query, JSON.stringify(res.data))
    }
    return res.data
  }
}

const App = (props) => {
  const { i18n } = useTranslation();

  const [origin, setOrigin] = useState("fr")

  useEffect(() => {
    setOrigin(i18n.language)
    document.documentElement.setAttribute('lang', i18n.language);
  }, [])


  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
        }
      })
    );
  });

  const setAlt = () => {
    var div = document.querySelector(".styles_bannerIcon__3zngJ");
    if (div){
      div.setAttribute("alt", "application The Running Collective");
    }
  }

  ReactGA.initialize(
      [
        {
          trackingId: "G-F0WQSXBFFB",
        }
      ], {testMode: false});

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>

        <Router>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
              <Route
                    path={process.env.PUBLIC_URL + "/pace"}
                    render={(routeProps) => (
                        <Allures {...routeProps} origin={origin} />
                    )}
                />
                <Route
                    path={process.env.PUBLIC_URL + "/"}
                    render={(routeProps) => (
                        <ShopGridFullWidth {...routeProps} origin={origin} />
                    )}
                />
                
              </Switch>
            </Suspense>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));

export function apiConversionRequest(queryUser, eventName, eventData){
  let fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
  let fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
  fbp = (fbp.length && fbp[0]) || null;
  fbc = (fbc.length && fbc[0]) || null;

  if(!fbc && window.location.search.includes('fbclid=')){
    fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
  }

  let body = {};

  if (eventData && eventData != ""){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "value": eventData.value,
            "currency": "EUR",
            "content_ids": [
                eventData.productID
            ],
            "content_name": eventData.productName,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else if (eventData && eventData != "Search"){
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "custom_data": {
            "search_string": eventData.search,
            "content_type": "product"
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  } else {
    body = {
      "data": [
        {
          "event_name": eventName,
          "event_time": new Date().toISOString(),
          "user_data": {
            "fbp": fbp,
            "fbc": fbc,
            "client_user_agent": navigator.userAgent,
            "client_ip_address": queryUser,
          },
          "event_source_url": window.location.origin + window.location.pathname,
          "action_source": "website"
        }
      ]
    }
  }
  let query = "https://graph.facebook.com/v13.0/1219438635083129/events?access_token=EAAStngtBnw0BAEbbRh60krdUcZCFix7MLaxQoh59ZCgeWZAubEv27fBp2nFNS7r5kJKum4BfbhygIhUfyRFkfdq9sfpD4ege2ZCBMzZAYwJn5yFpI6QTcC2BySqMnmNAIXMysobqP3XmYjGjLqfVDKCEukJ2aGiYZCBGdIxb6h05uuDq9ZBUocj"

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  fetch(query, requestOptions)
      .then(response => response.json())
}