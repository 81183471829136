import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {BrowserView, isTablet, isMobileOnly} from 'react-device-detect';
import {getProfileName} from "../../wrappers/header/HeaderTwo";
import {useTranslation} from "react-i18next";

const Logo = ({logoClass, location, offer, isPortrait }) => {
    const { t } = useTranslation();

    return (
    <div className={`${logoClass ? logoClass : ""} row`}>
      <Link to={process.env.PUBLIC_URL + "/"}>
          <img style={{marginLeft: isMobileOnly ? '15px' : '0', height: '40px'}} src="../../assets/img/noune/logo-trc.png" alt="The Running Collective"/>
          <span style={{fontWeight: '500', paddingTop: isMobileOnly ? '0' : '15px', marginLeft: '5px', fontSize: isMobileOnly ? '19px' : '28px', color: '#000', letterSpacing: '1px'}}>
            The Running Collective
          </span>
          <BrowserView>
              {!isMobileOnly ?
                  <div style={{fontSize: '12px', color: '#000', letterSpacing: '1px', paddingBottom: '10px'}}>
                      {t('descriptionhead')}
                      {offer && offer.title ?
                          <div style={{fontSize: '14px'}}>
                              <b>{offer.title}</b>
                          </div>
                          : <div style={{fontSize: '14px'}}>
                              <b>{getProfileName(location, t)}</b>
                          </div>}

                  </div>
                  : null }
          </BrowserView>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
  location: PropTypes.any,
    isPortrait:PropTypes.any,
};

export default Logo;
