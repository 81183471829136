import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setCurrency } from "../../redux/actions/currencyActions";
import { multilanguage } from "redux-multilanguage";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import {isMobileOnly, isTablet} from "react-device-detect";
import {InputAdornment, InputBase} from "@mui/material";
import {Search} from "@material-ui/icons";
import ReactPixel from "react-facebook-pixel";
import {apiConversionRequest, GetUserData} from "../../App";
import { useTranslation } from 'react-i18next';

const HeaderTwo = ({
  location,
  offer,
  setProductSearch,
  productSearch,
    strings
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [isPortrait, setIsPortrait] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleSearch = () => {
    let resWithoutSpace = productSearch.replaceAll(" ", "-")
    let res = resWithoutSpace.toLowerCase()
    window.location.assign(process.env.PUBLIC_URL + '/products-researched/'+res)
    ReactPixel.track('Search', {
      search_string: resWithoutSpace,
      content_type: 'product',
    })
    GetUserData(true).then(data =>
        apiConversionRequest(data.query, "Search", {search: resWithoutSpace})
    )
  }

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    setIsPortrait(portrait)
  }, [])

  window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
    const portrait = e.matches;
    if (portrait) {
      // do something
      setIsPortrait(true)
    } else {
      // do something else
      setIsPortrait(false)
    }
  });

  return (
    <header className="header-area clearfix header-hm9">
      <div className="container">
        <div className="header-top-area d-none d-lg-block">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              {/* language currency changer */}
              {/*<LanguageCurrencyChanger
                currency={currency}
                setCurrency={setCurrency}
                currentLanguageCode={currentLanguageCode}
                dispatch={dispatch}
              />*/}
            </div>
            <div className="col-lg-6 d-none d-lg-block text-center">
              {/* header logo */}
              <Logo
                imageUrl="/assets/img/logo/logo.png"
                logoClass="logo-hm-9"
                location={location}
                offer={offer}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-12">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-bottom sticky-bar header-res-padding header-padding-2`}
      >
        <div className="container" style={{maxWidth: "1500px"}}>
          <div className="row">
            <div className="col-10 d-block d-lg-none">
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo.png" location={location} offer={offer} isPortrait={isPortrait}/>
            </div>
            <div className="col-2 d-block d-lg-none">
              {/* Icon group */}
              <IconGroup />
            </div>
            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>
            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              {/* Nav menu */}
              <div style={{textAlign: 'center'}}>
                <InputBase
                    style={{borderRadius: "30px", border: "1px solid lightgray", textAlign: "center", marginBottom: "20px", paddingRight: "200px"}}
                    sx={{ m: 1 }}
                    placeholder={t('search')}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(e) => setProductSearch(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start" style={{paddingLeft: "10px"}}>
                        <Search />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch()
                      }
                    }}
                />
              </div>
            </div>

          </div>
          {/* mobile menu */}
          <MobileMenu />
          {isMobileOnly ?
              <div className="container-fluid">
                <div style={{fontSize: '9px', color: '#000', letterSpacing: '1px'}}>
                  {t('descriptionhead')}
                </div>
              </div>
              : null }
          {isMobileOnly ?
              <div className="container-fluid">
                <div style={{fontSize: '13px', color: '#000', letterSpacing: '1px', textAlign: 'center'}}>
                  <div>
                    { offer && offer.title ?
                        <b>{offer.title}</b>
                        :
                    <b>{getProfileName(location, t)}</b>}
                  </div>
                </div>
              </div>
              : null }

          {(isTablet && isPortrait) || isMobileOnly ?
              <div style={{textAlign: 'center'}}>
                <InputBase
                    style={{borderRadius: "30px", border: "1px solid lightgray", textAlign: "center", marginTop: "10px"}}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={t('search')}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(e) => setProductSearch(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start" style={{paddingLeft: "10px"}}>
                        <Search />
                      </InputAdornment>
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch()
                      }
                    }}
                />
              </div>
              : null }
        </div>
      </div>
    </header>
  );
};

export function getProfileName(location, t){

  if (location && location.pathname){
    switch (location.pathname) {
      case "/athletisme":
        return t('trackandfield')
      case "/products/spikes":
        return t('trackandfield')
      case "/running":
        return t('running')
      case "/trail":
        return t('trail')
    }
  }

  if (location && location.search) {
    if (location.search.includes("athletisme")){
      return t('trackandfield')
    } else if (location.search.includes("running")) {
      return t('running')
    } else if (location.search.includes("trail")) {
      return t('trail')
    }
  }
}

HeaderTwo.propTypes = {
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  offer: PropTypes.object,
  strings: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(HeaderTwo));
